import React from "react";
import { indexPageHolder } from "./index.module.scss";
import Seo from "../components/components/Seo";

export default function HomePage() {
   return (
      <article className={indexPageHolder}>
         <Seo title="Good Chaos - Homepage" />
      </article>
   );
}
